<template>
  <div  class="modalClass" ref="container">
    <a-modal
      :visible="visible"
      title=""
      :width="350"
      centered
      :destroyOnClose="true"
      :getContainer="() => container"
      @cancel="close"
    >
      <!--  注销账号form  :footer="null" -->
      <div class="logoutContentT">
        <div>请复制连接去浏览器打开</div>
        <div>复制</div>
      </div>

      <template v-slot:footer>
        <a-button></a-button>
        <a-button> </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    visible: Boolean,
    init: String,
  },
  setup(props, context) {
    const container = ref();
    watch(
      () => props.init,
      init => {
        console.log('tishi', init);

      },
      { deep: true }
    );

    return {
      container,

      close() {
        context.emit('update:visible', false);
      },

    }

  }


};
</script>

<style scoped lang='less'>

@media (max-width: 750px){
  .modalClass /deep/ .ant-modal-body {
    height: 400px;
    overflow: auto;
    padding: 0px 24px;
    margin-top: 24px;
    font-size: 16px;
  }

}

.modalClass /deep/ .ant-modal-footer {
  padding:0px;
}

</style>